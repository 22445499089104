import { ICredential, ICredentialSecurityBreakdown, ISecurityStats } from '../interfaces';

const numDaysSinceUpdate = (credential: ICredential) =>
    Math.round((new Date().getTime() - new Date(credential.passwordChangedDate).getTime()) / (1000 * 3600 * 24));

export const calculateSecurityStats = (credentials: ICredential[]): ISecurityStats => {
    const fingerprintMap = credentials.reduce((map, credential) => {
        map.set(credential.fingerprint, [...(map.get(credential.fingerprint) || []), credential]);
        return map;
    }, new Map<string, ICredential[]>());

    const totals = credentials.reduce(
        (score, cred) => {
            score.total += 12;
            score.score += cred.score;
            score.credentials++;

            if (numDaysSinceUpdate(cred) > 365) {
                score.old++;
            } else {
                score.score++;
            }

            if (cred.risks?.length > 0) {
                score.risks++;
            } else {
                score.score++;
            }

            if (cred.score < 2) {
                score.weak++;
            }

            if (cred.totpKey) {
                score.mfa++;
                score.score += 4;
            }

            const dupes = fingerprintMap.get(cred.fingerprint)!;

            if (dupes.length > 1) {
                score.duplicates++;
            } else {
                score.score += 2;
            }

            return score;
        },
        { total: 0, score: 0, old: 0, risks: 0, credentials: 0, weak: 0, mfa: 0, duplicates: 0 },
    );

    const percentScore = Math.round((totals.score / totals.total) * 100);

    return { ...totals, percentScore };
};

export const getCredentialsSecurityBreakdown = (credentials: ICredential[]) => {
    const fingerprintMap = credentials.reduce((map, credential) => {
        map.set(credential.fingerprint, [...(map.get(credential.fingerprint) || []), credential]);
        return map;
    }, new Map<string, ICredential[]>());

    const getCredentialSecurity = (credential: ICredential) => {
        const total = 12;
        let { score } = credential;
        const issues: any = {};

        if (numDaysSinceUpdate(credential) > 365) {
            issues.old = true;
        } else {
            score++;
        }

        if (credential.risks?.length < 1) {
            score++;
        } else {
            issues.atRisk = true;
        }

        if (credential.score < 2) {
            issues.weak = true;
        }

        if (credential.totpKey) {
            score += 4;
        }

        const dupes = fingerprintMap.get(credential.fingerprint)!;

        if (dupes.length > 1) {
            issues.duplicate = true;
            issues.duplicates = dupes.filter((cred) => cred.id !== credential.id).map((cred) => cred.id);
        } else {
            score += 2;
        }

        return {
            score,
            percent: Math.round((score / total) * 100),
            issues,
        };
    };

    return credentials
        .sort((a, b) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        })
        .map((cred) => ({
            credential: cred,
            breakdown: getCredentialSecurity(cred),
        })) as ICredentialSecurityBreakdown[];
};
