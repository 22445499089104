import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MODAL_DATA, MODAL_REF, ModalComponent } from '@passbot/angular/modal';
import { ToastrService } from 'ngx-toastr';
import { IUserAuthDevice } from '@passbot/shared';
import { DevicesFacade } from '../../../+state';

interface IEditDeviceData {
    device: IUserAuthDevice;
}

@Component({
    selector: 'passbot-rename-device',
    templateUrl: './rename-device.component.html',
})
export class RenameDeviceComponent implements OnInit {
    public processing = this.deviceFacade.isProcessing$;

    public nameForm = new FormGroup({
        name: new FormControl('', Validators.required),
    });

    constructor(
        @Inject(MODAL_REF) private readonly modal: ModalComponent,
        @Inject(MODAL_DATA) public readonly data: IEditDeviceData,
        private readonly deviceFacade: DevicesFacade,
        private readonly toastr: ToastrService,
    ) {}

    public ngOnInit() {
        this.nameForm.patchValue({
            name: this.data.device.displayName,
        });
        this.nameForm.markAsPristine();
    }

    public cancel() {
        this.modal.close();
    }

    public async save() {
        if (!this.nameForm.controls.name.value) {
            return;
        }

        await this.deviceFacade.renameDeviceAsync(this.data.device.id, this.nameForm.controls.name.value);
        this.toastr.success(`Device has been renamed to ${this.nameForm.controls.name.value}`, 'Update Success');
        this.modal.close();
    }
}
