import { createReducer, on } from '@ngrx/store';
import { IUserAuthDevice } from '@passbot/shared';
import { createEntityAdapter } from '@ngrx/entity';
import { entityErrorFn, entityStartProcessing, entityStopProcessing, getDefaultEntityState, IEntityState } from '@passbot/angular/common';
import {
    addDeviceInProgess,
    addDeviceSuccess,
    clearDevices,
    loadDevices,
    loadDevicesError,
    loadDevicesSuccess,
    removeDevice,
    removeDeviceError,
    removeDeviceSuccess,
    renameDevice,
    renameDeviceError,
    renameDeviceSuccess,
} from './devices.actions';

export const DEVICES_FEATURE_KEY = 'devices';

export const adapter = createEntityAdapter<IUserAuthDevice>();

export type DevicesState = IEntityState<IUserAuthDevice>;

export const initialState: DevicesState = getDefaultEntityState(adapter);

export const DevicesReducers = createReducer(
    initialState,
    on(loadDevices, (state) => entityStartProcessing(state)),
    on(loadDevicesSuccess, (state, devices) => entityStopProcessing(adapter.upsertMany(devices.items, state))),
    on(loadDevicesError, (state, error) => entityErrorFn(entityStopProcessing(state))(error.msg)),
    on(clearDevices, (state) => adapter.removeAll(state)),
    on(addDeviceInProgess, (state) => entityStartProcessing(state)),
    on(addDeviceSuccess, (state, props) => adapter.upsertOne(props.item, entityStopProcessing(state))),
    on(removeDevice, (state) => entityStartProcessing(state)),
    on(removeDeviceSuccess, (state, { item }) => entityStopProcessing(adapter.removeOne(item.id!, state))),
    on(removeDeviceError, (state, { msg }) => entityStopProcessing({ ...state, error: msg })),
    on(renameDevice, (state) => entityStartProcessing(state)),
    on(renameDeviceSuccess, (state, props) => adapter.updateOne({ id: props.id, changes: { displayName: props.name } }, entityStopProcessing(state))),
    on(renameDeviceError, (state, { msg }) => entityStopProcessing({ ...state, error: msg })),
);
