import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICredential } from '@passbot/shared';
import { CredentialsFacade } from '../../+state';

@Component({
    selector: 'passbot-credential-item',
    templateUrl: './credential-item.component.html',
})
export class CredentialItemComponent implements OnInit {
    @Input() public credentialId: string;
    public credential$: Observable<ICredential>;

    constructor(private readonly credentialFacade: CredentialsFacade) {}

    public ngOnInit() {
        this.credential$ = this.credentialFacade.getId$(this.credentialId) as Observable<ICredential>;
    }
}
