import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';

import { AuthModule } from '@passbot/angular/auth';
import { ChargebeeModule, ChargebeeService } from '@passbot/angular/chargebee';
import { APP_CONFIG, ConfigModule, ConfigService } from '@passbot/angular/config';
import { CredentialsModule } from '@passbot/angular/credentials';
import { PermissionsModule } from '@passbot/angular/permissions';
import { ScanModule } from '@passbot/angular/scan';
import { AngularSocketIoModule } from '@passbot/angular/socket-io';
import { TenantModule, TenantService } from '@passbot/angular/tenant';
import { WebauthnModule } from '@passbot/angular/webauthn';

import { KeepaliveInterceptor, KeepaliveService } from '@passbot/angular/keepalive';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminModule } from '@passbot/angular/admin';
import { ViewContainerService } from '@passbot/angular/common';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ConfigModule,
        AngularSocketIoModule,
        AdminModule,
        RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking', paramsInheritanceStrategy: 'always' }),
        StoreModule.forRoot(
            {
                router: routerReducer,
            },
            {
                metaReducers: !environment.production ? [] : [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true,
                },
            },
        ),
        EffectsModule.forRoot([]),
        // @todo Re-enable on production launch
        //*!environment.production ? StoreDevtoolsModule.instrument(): [],
        StoreDevtoolsModule.instrument({ connectInZone: true }),
        StoreRouterConnectingModule.forRoot(),
        ToastrModule.forRoot({
            messageClass: 'toast-message text-sm',
            toastClass: 'ngx-toastr !shadow-black !shadow-xl',
            positionClass: 'toast-bottom-right',
        }),
        AuthModule,
        ChargebeeModule,
        TenantModule,
        WebauthnModule,
        CredentialsModule,
        PermissionsModule,
        ScanModule,
        ReactiveFormsModule,
        NgSelectModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    configService: ConfigService,
                    chargebeeService: ChargebeeService,
                    tenantService: TenantService,
                    keepaliveService: KeepaliveService,
                ) =>
                async () => {
                    configService.loadConfig(environment);
                    chargebeeService.inject();
                    await tenantService.init();
                    keepaliveService.init();
                },
            deps: [ConfigService, ChargebeeService, TenantService, KeepaliveService, HttpClient],
            multi: true,
        },
        {
            provide: APP_CONFIG,
            useValue: environment,
        },
        { provide: HTTP_INTERCEPTORS, useClass: KeepaliveInterceptor, multi: true },
        ViewContainerService,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
