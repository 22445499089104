import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs/operators';
import { APIService } from '@passbot/angular/api';
import { IUserPermission } from '@passbot/shared';
import { of } from 'rxjs';
import {
    loadPermissions,
    loadPermissionsError,
    loadPermissionsSuccess,
    removeUserPermission,
    removeUserPermissionError,
    removeUserPermissionSuccess,
    updateUserPermission,
    updateUserPermissionError,
    updateUserPermissionSuccess,
    updateCredentialGroupPermissions,
    updateCredentialGroupPermissionsSuccess,
    updateCredentialGroupPermissionsError,
} from './permissions.actions';

@Injectable()
export class PermissionsEffects {
    public loadPermissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPermissions),
            fetch({
                // provides an action
                run: () => {
                    return this.apiService
                        .call<IUserPermission[]>('/permissions')
                        .pipe(map((permissions) => loadPermissionsSuccess({ permissions })));
                },

                onError: (err) => {
                    return of(loadPermissionsError({ msg: 'Failed to load user permissions' }));
                },
            }),
        ),
    );

    public removeUserPermission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeUserPermission),
            fetch({
                run: ({ userId, permissionId }) => {
                    return this.apiService
                        .delete(`/user/${userId}/permission/${permissionId}`)
                        .pipe(map(() => removeUserPermissionSuccess({ userId, permissionId })));
                },
                onError: (err) => {
                    return of(removeUserPermissionError({ msg: 'Failed to remove user permission' }));
                },
            }),
        ),
    );

    public updateUserPermission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateUserPermission),
            fetch({
                run: ({ userId, permission }) => {
                    return this.apiService
                        .put(`/user/${userId}/permission/${permission.id}`, permission)
                        .pipe(map(() => updateUserPermissionSuccess({ userId, permission })));
                },
                onError: (err) => {
                    return of(updateUserPermissionError({ msg: 'Failed to update user permission' }));
                },
            }),
        ),
    );

    public updateCredentialGroupPermissions = createEffect(() =>
        this.actions$.pipe(
            ofType(updateCredentialGroupPermissions),
            fetch({
                run: (props) =>
                    this.apiService
                        .put<IUserPermission[]>(`/credential-group/${props.id}/permissions`, props.permissions)
                        .pipe(map((response) => updateCredentialGroupPermissionsSuccess({ id: props.id, permissions: response }))),
                onError: (err) => of(updateCredentialGroupPermissionsError({ msg: 'failed to update group permissions' })),
            }),
        ),
    );

    constructor(private readonly actions$: Actions, private readonly apiService: APIService) {}
}
