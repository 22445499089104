import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { firstValueFrom, merge, Observable } from 'rxjs';
import { IUserAuthDevice } from '@passbot/shared';
import { Actions, ofType } from '@ngrx/effects';
import { IStoreResponse } from '@passbot/angular/common';
import { getError, getIsProcessing, selectAll } from './devices.selectors';
import {
    addDevice,
    loadDevices,
    loadDevicesError,
    loadDevicesSuccess,
    removeDevice,
    removeDeviceError,
    removeDeviceSuccess,
    renameDevice,
    renameDeviceSuccess,
} from './devices.actions';

@Injectable()
export class DevicesFacade {
    constructor(private readonly store: Store, private readonly actions: Actions) {}

    public isProcessing$ = this.store.pipe(select(getIsProcessing));
    public getAll$ = this.store.pipe(select(selectAll)) as Observable<IUserAuthDevice[]>;
    public getError$ = this.store.pipe(select(getError));

    public loadDevices() {
        this.store.dispatch(loadDevices());
    }

    public loadDevicesAsync(): Promise<IStoreResponse<IUserAuthDevice[]>> {
        this.loadDevices();

        const loadSuccess = this.actions.pipe(ofType(loadDevicesSuccess));
        const loadError = this.actions.pipe(ofType(loadDevicesError));

        return firstValueFrom(merge(loadSuccess, loadError));
    }

    public addDevice(deviceType: string, target: string, name: string) {
        this.store.dispatch(addDevice({ deviceType, target, name, isRegistration: true }));
    }

    public removeDevice(device: IUserAuthDevice) {
        this.store.dispatch(removeDevice({ item: device }));
    }

    public removeDeviceAsync(device: IUserAuthDevice) {
        this.removeDevice(device);

        const updateSuccess = this.actions.pipe(ofType(removeDeviceSuccess));
        const updateError = this.actions.pipe(ofType(removeDeviceError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }

    public renameDevice(id: string, name: string) {
        this.store.dispatch(renameDevice({ id, name }));
    }

    public renameDeviceAsync(deviceId: string, name: string) {
        this.renameDevice(deviceId, name);

        const updateSuccess = this.actions.pipe(ofType(renameDeviceSuccess));
        const updateError = this.actions.pipe(ofType(removeDeviceError));

        return firstValueFrom(merge(updateSuccess, updateError));
    }
}
