<div class="space-y-8 text-white min-w-[400px] text-left" *ngIf="(processing | async) === false" [formGroup]="nameForm">
    <h3 class="text-center font-semibold text-lg text-brand">Rename Device</h3>
    <div class="">
        <label class="text-xs mb-1" [ngClass]="{'!text-red-500': nameForm.controls.name.invalid && nameForm.controls.name.touched }">Name*:</label>
        <input
                autofocus
                formControlName="name"
                autocomplete="off"
                type="text"
                [ngClass]="{'!ring-red-500': nameForm.controls.name.invalid && nameForm.controls.name.touched}"
                class="block min-h-[36px] pl-4 w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white/50 sm:text-sm sm:leading-6"/>
    </div>
    <div class="flex justify-between mt-8">
        <div (click)="cancel()" class="cursor-pointer inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
            Cancel
        </div>
        <button
                (click)="save()"
                [disabled]="nameForm.invalid"
                class="inline-flex justify-center rounded-md bg-brand px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-slate-800 disabled:text-slate-900 disabled:cursor-not-allowed">
            Save
        </button>
    </div>
</div>

<div class="p-8 text-white text-center space-y-4" *ngIf="processing | async">
    <div><i class="fa-regular fa-spinner fa-spin-pulse text-6xl text-white"></i></div>
</div>
