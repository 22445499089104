import { Component, Input, OnInit } from '@angular/core';
import { IUserAuthDevice, mask } from '@passbot/shared';
import { tap } from 'rxjs';
import { ModalService } from '@passbot/angular/modal';
import { DevicesFacade } from '../../+state';
import { AddMobileDeviceComponent } from './add-mobile-device/add-mobile-device.component';
import { AddLocalDeviceComponent } from './add-local-device/add-local-device.component';
import { AddEmailDeviceComponent } from './add-email-device/add-email-device.component';
import { RemoveDeviceComponent } from './remove-device/remove-device.component';
import { RenameDeviceComponent } from './rename-device/rename-device.component';

@Component({
    selector: 'passbot-manage-devices',
    templateUrl: './manage-devices.component.html',
})
export class ManageDevicesComponent implements OnInit {
    @Input() public showAddButtons = true;
    public devices$ = this.devicesFacade.getAll$.pipe(tap(this.handleDevices.bind(this)));
    public mask = mask;
    public hasMobile = false;
    public hasDevice = false;
    public hasLocal = false;
    public hasEmail = false;
    public numDevices: number = 0;

    constructor(private readonly devicesFacade: DevicesFacade, private readonly modalService: ModalService) {}

    public ngOnInit() {
        this.devicesFacade.loadDevices();
    }

    public showModal(modal: string) {
        switch (modal) {
            case 'mobile':
                this.modalService.show(AddMobileDeviceComponent);
                break;
            case 'local':
                this.modalService.show(AddLocalDeviceComponent);
                break;
            case 'email':
                this.modalService.show(AddEmailDeviceComponent);
                break;
            default:
            // do nothing
        }
    }

    public handleDevices(devices: IUserAuthDevice[]) {
        this.hasMobile = !!devices.find((device) => device.type === 'mobile');
        this.hasLocal = !!devices.find((device) => device.type === 'local');
        this.hasEmail = !!devices.find((device) => device.type === 'email');
        this.hasDevice = devices.length > 0;
        this.numDevices = devices.length;
    }

    public deleteDevice(device: IUserAuthDevice) {
        if (this.numDevices < 2) {
            return;
        }
        this.modalService.show(RemoveDeviceComponent, { data: device });
    }

    public renameDevice(device: IUserAuthDevice) {
        this.modalService.show(RenameDeviceComponent, { data: { device } });
    }
}
