// eslint-disable-next-line max-classes-per-file
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { PassbotCommonModule } from '@passbot/angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ReactiveFormsModule } from '@angular/forms';
import { WebauthnModule } from '@passbot/angular/webauthn';
import { ModalModule } from '@passbot/angular/modal';
import { devicesRoutes } from './lib.routes';
import { DEVICES_FEATURE_KEY, DevicesReducers } from './+state/devices.reducer';
import { ManageDevicesComponent } from './components/manage-devices/manage-devices.component';
import { DevicesEffects, DevicesFacade } from './+state';
import { AddMobileDeviceComponent } from './components/manage-devices/add-mobile-device/add-mobile-device.component';
import { AddLocalDeviceComponent } from './components/manage-devices/add-local-device/add-local-device.component';
import { AddEmailDeviceComponent } from './components/manage-devices/add-email-device/add-email-device.component';
import { IndexComponent } from './components/index/index.component';
import { RemoveDeviceComponent } from './components/manage-devices/remove-device/remove-device.component';
import { RenameDeviceComponent } from './components/manage-devices/rename-device/rename-device.component';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(DEVICES_FEATURE_KEY, DevicesReducers), EffectsModule.forFeature([DevicesEffects])],
    providers: [DevicesFacade],
})
export class DevicesStoreModule {}

@NgModule({
    imports: [CommonModule, DevicesStoreModule, PassbotCommonModule, ReactiveFormsModule, WebauthnModule, ModalModule],
    declarations: [
        ManageDevicesComponent,
        AddMobileDeviceComponent,
        AddLocalDeviceComponent,
        AddEmailDeviceComponent,
        IndexComponent,
        RemoveDeviceComponent,
        RenameDeviceComponent,
    ],
    exports: [ManageDevicesComponent],
})
export class DevicesModule {}

@NgModule({
    imports: [DevicesModule, RouterModule.forChild(devicesRoutes)],
})
export class DevicesModuleRouted {}
