import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable, tap } from 'rxjs';

import { UserFacade } from '@passbot/angular/auth';
import { ModalService } from '@passbot/angular/modal';
import { SidebarService } from '@passbot/angular/sidebar';
import { ICredential, ICredentialGroup, IUser, Permissions } from '@passbot/shared';

import { PermissionsFacade, UsersFacade } from '@passbot/angular/permissions';
import { AddCredentialModalComponent, CreateVaultModalComponent } from '@passbot/angular/admin';
import { ViewCredentialModalComponent } from '../view-credential-modal/view-credential-modal.component';
import { CredentialGroupsFacade, CredentialsFacade } from '../../+state';
import { DeleteCredentialModalComponent, IDeleteCredentialModalData } from '../delete-credential-modal/delete-credential-modal.component';
import { ManageUsersComponent } from '../manage-users/manage-users.component';
import { DeleteGroupModalComponent } from '../delete-group-modal/delete-group-modal.component';

@Component({
    selector: 'passbot-credential-group',
    templateUrl: './credential-group.component.html',
})
export class CredentialGroupComponent implements OnInit {
    public credentialGroup$: Observable<ICredentialGroup | undefined>;
    public credentials$: Observable<ICredential[]>;
    public users$: Observable<IUser[]>;
    public notFound = false;
    public user: IUser;
    public writePermission = Permissions.Write;
    public adminPermission = Permissions.Admin;

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly credentialGroupsFacade: CredentialGroupsFacade,
        private readonly credentialsFacade: CredentialsFacade,
        private readonly userFacade: UserFacade,
        private readonly usersFacade: UsersFacade,
        private readonly permissionsFacade: PermissionsFacade,
        private readonly sidebarService: SidebarService,
        private readonly modalService: ModalService,
        private readonly router: Router,
    ) {}

    public async ngOnInit() {
        this.user = await this.userFacade.getUser();

        this.activatedRoute.params.subscribe((params) => {
            const { credentialGroupId } = params;

            this.credentialGroup$ = this.credentialGroupsFacade.getById$(credentialGroupId).pipe(
                tap(async (credentialGroup) => {
                    this.notFound = false;

                    if (!credentialGroup) {
                        void this.router.navigateByUrl('/credentials');
                        return;
                    }

                    const userPermission = await firstValueFrom(this.permissionsFacade.getByLoggedInUserAndGroupId$(credentialGroup.id));

                    if (!(userPermission || this.user.tenantAdmin)) {
                        this.notFound = true;
                        return;
                    }

                    this.users$ = this.usersFacade.getByGroupId$(credentialGroup.id, true);

                    this.credentials$ = this.credentialsFacade.getByGroupId$(credentialGroup.id);
                }),
            );

            // this.credentialGroupsFacade.fetchCredentialGroup(credentialGroupId); @todo consider if we need this or not, was a way to keep page relatively hydrated but not sure if we need to spam with changes, maybe the socket update work in future will do a better job
        });
    }

    public manageUsers(credentialGroup: ICredentialGroup) {
        this.sidebarService.show(ManageUsersComponent, {
            data: {
                credentialGroup: credentialGroup,
            },
            title: 'Manage Group Users',
        });
    }

    public delete(credential: ICredential, groupId: string) {
        //this.modalService.show();
        this.modalService.show<IDeleteCredentialModalData>(DeleteCredentialModalComponent, {
            data: {
                credential,
                groupId,
            },
        });
    }

    public deleteGroup(credentialGroup: ICredentialGroup) {
        this.modalService.show(DeleteGroupModalComponent, { data: { credentialGroup } });
    }

    public renameGroup(credentialGroup: ICredentialGroup) {
        this.modalService.show(CreateVaultModalComponent, { data: { credentialGroup } });
    }

    public async edit(credential: ICredential) {
        const groups = await this.credentialGroupsFacade.groupsByCredentialIdAsync(credential.id);
        this.modalService.show(AddCredentialModalComponent, { data: { credential: { ...credential, credentialGroups: groups } } });
    }

    public view(credential: ICredential) {
        this.modalService.show(ViewCredentialModalComponent, { data: { credentialId: credential.id } });
    }
}
